import React from "react"
import facebook from "../../assets/images/facebook.svg"
import linkedin from "../../assets/images/linkedin.svg"
import call from "../../assets/images/icon-phone.svg"
import mail from "../../assets/images/icon-email.svg"
import location from "../../assets/images/icon-location.svg"

const ContactInfo = () => {
  return (
    <div className="contact-info-area">
      <div className="contact-info-card">
        <h3>Corporate Head Office</h3>
        <div className="info-text">
          <img src={call} alt="call icon" />
          <h5 className="info-label">1300 855 699</h5>
        </div>
        <div className="info-text">
          <img src={mail} alt="mail icon" />
          <a href="mailto:oxygen@oxygen.com.au">oxygen@oxygen.com.au</a>
        </div>
        <div className="info-text">
          <img src={location} alt="location icon" />
          <h5 className="info-label">Level 12, 92 Pitt St Sydney NSW 2000</h5>
        </div>
      </div>

      <div className="social-media">
        <h3 className="social-media__title">
          Feel free to contact us on social media
        </h3>
        <div className="social-media__links">
          <a
            href="https://www.facebook.com/OxygenHomeLoans"
            target="_blank"
            rel="noreferrer"
          >
            <img src={facebook} alt="facebook icon" />
          </a>
          <a
            href="https://www.linkedin.com/company/oxygen-home-loans/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={linkedin} alt="linkedin icon" />
          </a>
        </div>
      </div>
    </div>
  )
}

export default ContactInfo
