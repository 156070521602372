import React from "react"
import ReactWOW from "react-wow"
import scrollTo from "gatsby-plugin-smoothscroll"

import ThreeBillion from "../../assets/images/careers/icon-3-billion.svg"
import TenBillion from "../../assets/images/careers/icon-10-billion.svg"
import TenThousand from "../../assets/images/careers/icon-10-thousand.svg"
import SeventeenY from "../../assets/images/careers/icon-17-years.svg"
import Eghteen from "../../assets/images/careers/icon-18-years.svg"

const Banner = () => {
  return (
    <div className="careers-hero-banner mb-5">
      <div className="container">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-12 col-md-8">
            <div className="main-banner-content">
              <p className="h1-accent">Why Oxygen?</p>
              <h1>We are Experienced with Achievements to Match</h1>
              <h2>Become a Part of Our Elite Broker Network</h2>
              <ReactWOW delay=".05s" animation="fadeInUp">
                <div className="btn-box">
                  <button
                    className="default-btn primary-btn btn-lg mt-1 mt-md-2 mt-lg-3 mb-2 mr-0 mx-2"
                    onClick={() => scrollTo("#careers-form", "start")}
                  >
                    Apply Now
                  </button>
                </div>
              </ReactWOW>
            </div>
          </div>
        </div>
      </div>
      <div className="container mt-5">
        <div className="row d-flex justify-stats mt-5">
          <div className="col-6 col-sm-4 col-md-2">
            <div className="stats-container">
              <img className="mb-2" src={TenBillion} alt="3-billion" />
              <h4>$10 Billion</h4>
              <h5 className="why-item-text">
                Total value of home loans settled
              </h5>
            </div>
          </div>
          <div className="col-6 col-sm-4 col-md-2">
            <div className="stats-container">
              <img className="mb-2" src={ThreeBillion} alt="10-billion" />
              <h4>$3.1 Billion</h4>
              <h5 className="why-item-text">Loan Book</h5>
            </div>
          </div>
          <div className="col-6 col-sm-4 col-md-2">
            <div className="stats-container">
              <img className="mb-2" src={TenThousand} alt="ten-thousand" />
              <h4>10,000+</h4>
              <h5 className="why-item-text">Active Loans</h5>
            </div>
          </div>
          <div className="col-6 col-sm-4 col-md-2">
            <div className="stats-container">
              <img className="mb-2" src={SeventeenY} alt="17-years" />
              <h4>17 years</h4>
              <h5 className="why-item-text">Average broker experience</h5>
            </div>
          </div>
          <div className="col-6 col-sm-4 col-md-2">
            <div className="stats-container">
              <img className="mb-2" src={Eghteen} alt="18-years" />
              <h4>18</h4>
              <h5 className="why-item-text">Years old</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner
